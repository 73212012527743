import React from "react";
import slugs from "../../resources/slugs";
import {
  OverviewIcon,
  UsersIcon,
  TransactionsIcon,
  SettingsIcon,
  LogsIcon,
  QrCodeIcon,
  OtpIcon,
  MarkettingIcon,
  PromotionsIcon,
  TechIcon,
  WayaUserIcon,
  UserFeaturesIcon,
  ContentIcon,
  KycIcon,
  cardIcon,
} from "../../components/svg/sidebar";
import Icon from "@ant-design/icons";

export default function getMenuData() {
  return [
    {
      title: "Dashboard",
      key: "dashboards",
      icon: <Icon component={OverviewIcon} />,
      children: [
        {
          title: "Overview",
          key: "overview",
          url: slugs.dashboard,
        },
        {
          title: "Overview Analysis",
          key: "overviewAnalysis",
          url: slugs.dashboardAnalysis,
        },
        ]
    },
    {
      title: "Users",
      key: "users",
      icon: <Icon component={UsersIcon} />,
      // roles: ['admin'],
      children: [
        {
          title: "Manage Users",
          key: "manageUsers",
          url: slugs.users,
        },
      /*  {
          title: "Manage Temporary Users",
          key: "temporalUsers",
          url: slugs.temporary_users,
        },*/
        {
          title: "Manage Refferals",
          key: "refferals",
          url: slugs.referral,
        },
        {
          title: "Manage Admin Users",
          key: "manageAdmin",
          url: slugs.admin,
        },

        // {
        //   title: 'Manage Agents',
        //   key: 'manageAgent',
        //   url: slugs.agents,
        // },
      ],
    },
    {
      title: "Transactions",
      key: "transactions",
      icon: <Icon component={TransactionsIcon} />,
      children: [
        {
          title: "All Transactions",
          key: "allTransations",
          url: slugs.all_transactions,
        },
        // {
        //   title: "Transfer Inceptors",
        //   key: "inceptor",
        //   url: slugs.transfer_inceptor,
        // },
        {
          title: "SMU Transactions",
          key: "smuTransations",
          url: slugs.smu_transactions,
        },
        /*{
          title: "Queued Transactions",
          key: "queuedTransactions",
          url: slugs.queued_transactions,
        },*//*
        {
          title: "Transactions Charges",
          key: "transactionCharges",
          url: slugs.transaction_charge,
        },*/
        {
          title: "Bills Payment",
          key: "billsPayment",
          url: slugs.bills_pay,
        },
        {
          title: "Payment Requests",
          key: "paymentRequest",
          url: slugs.payment_request,
        },
      /*  {
          title: "Refunds",
          key: "refunds",
          url: slugs.refunds,
        },
       
        {
          title: "Retrieve Payments",
          key: "retrievePayment",
          url: slugs.retrieve_payment,
        },*/
        {
          title: "Non Waya Transfers",
          key: "nonWayaTransfer",
          url: slugs.non_waya_transfer,
        },
        {
          title: "Commissions",
          key: "commission",
          url: slugs.trans_commissions,
        },
        {
          title: "Accounts",
          key: "accounts",
          url: slugs.trans_accounts,
        },
      ],
    },
    {
      title: "Business Reporting",
      key: "rports",
      icon: <Icon component={TransactionsIcon} />,
      children: [
        {
          title: "Manual Reports",
          key: "manualTransations",
          url: slugs.report,
        },
        {
          title: "All Transactions Categories Report",
          key: "allTransationsRep",
          url: slugs.all_transactions,
        },
        {
          title: "Users Payment Request Report",
          key: "paymentRequest",
          url: slugs.payment_request,
        },
        
        /*{
          title: "Users Bills Payment Report",
          key: "billsPayment",
          url: slugs.bills_pay,
        },
        /*{
          title: "Users Withdrawal Report",
          key: "withdrawal",
          url: slugs.all_transactions,
        },
        {
          title: "Users Funding Report",
          key: "fundingReport",
          url: slugs.all_transactions,
        },
        {
          title: "Users Internal Transfers Report",
          key: "internalreport",
          url: slugs.all_transactions,
        },*/
        {
          title: "Users Non Waya Transfer Report",
          key: "nonWayaTransfer",
          url: slugs.non_waya_transfer,
        },
        /*{
          title: "Users Profile Details Report",
          key: "profileReport",
          url: slugs.non_waya_transfer,
        },
        /*{
          title: "Users KYC Report",
          key: "kyc",
          url: slugs.user_tier_levels,
        },*/
        {
          title: "QR Codes Report",
          key: "qrreport",
          url: slugs.qr_codes,
        },
        {
          title: "Field Marketers Report",
          key: "fieldM",
          url: slugs.qr_codes,
        },        
        
        {
          title: "Dispute Report",
          key: "dispute",
          url: slugs.dispute,
        },
             
        {
          title: "Audit Log Report",
          key: "auditReport",
          url: slugs.admin_audit,
        },
        {
          title: "Request Log Report",
          key: "requestReport",
          url: slugs.requests_logs,
        },
        {
          title: "Referral Report",
          key: "referral",
          url: slugs.referral,
        },
        {
          title: "Commission Report",
          key: "commission",
          url: slugs.trans_commissions,
        },
        {
          title: "Users Bank Account Report",
          key: "accountReport",
          url: slugs.trans_accounts,
        },
        {
          title: "Waya Official Accounts Transaction Report",
          key: "wayaOfficialTranReport",
          url: slugs.waya_official,
        },
        /*{
          title: "Waya Official Withrawal Report",
          key: "wayaOfficialWithdrawalReport",
          url: slugs.waya_official,
        },
        {
          title: "Waya Official Internal Transfer Report",
          key: "wayaOfficialInternalReport",
          url: slugs.waya_official,
        },
        {
          title: "Waya Official Funding Report",
          key: "wayaOfficialFundingReport",
          url: slugs.waya_official,
        },*/
        {
          title: "Waya Official Bank Accounts Report",
          key: "wayaOfficialAccountsReport",
          url: slugs.waya_official,
        },
        
      ],
    },
    {
      title: "KYC",
      key: "manageKyc",
      icon: <Icon component={KycIcon} />,
      children: [
        {
          title: "User Tier Levels",
          key: "userTierLevels",
          url: slugs.user_tier_levels,
        },
        {
          title: "Business Types",
          key: "businessTypes",
          url: slugs.business_types,
        },
        {
          title: "KYC Settings",
          key: "kycSettings",
          url: slugs.kyc_settings,
        },
      ],
    },
    {
      title: "Logs",
      key: "logs",
      icon: <Icon component={LogsIcon} />,
      children: [
        {
          title: "Dispute",
          key: "dispute",
          url: slugs.dispute,
        },
        /*{
          title: "Third Party Disputes",
          key: "third_party_disputes",
          url: slugs.third_party_dispute_log,
        },*/
        {
          title: "Requests Log",
          key: "requests_log",
          url: slugs.requests_logs,
        },
        {
          title: "Admin Audit Log",
          key: "admin_audit_log",
          url: slugs.admin_audit,
        },
        {
          title: "Fraud Management",
          key: "fraud_management",
          url: slugs.fraudManagement_log,
        },
      ],
    },
    {
      title: "Manage Cards",
      key: "cards",
      icon: <Icon component={cardIcon} />,
      children: [
        {
          title: "Cards",
          key: "dispute",
          url: slugs.cards,
        },
        {
          title: "Bulk Card",
          key: "bulkcards",
          url: slugs.bulk_cards,
        },
      ]
    },
    {
      title: "QR Codes",
      key: "qrCode",
      icon: <Icon component={QrCodeIcon} />,
      url: slugs.qr_codes,
    },
    {
      title: "OTP",
      key: "otp",
      icon: <Icon component={OtpIcon} />,
      url: slugs.otp,
    },

    {
      title: "Tech",
      key: "trading",
      icon: <Icon component={TechIcon} />,
      children: [
        {
          title: "Tech Admin Vendors",
          key: "techAdminVendors",
          url: slugs.tech_admin,
        },
        {
          title: "Pricing",
          key: "pricing",
          url: slugs.tech_pricing,
        },
        {
          title: "Commission",
          key: "commission settings",
          url: slugs.agre_bill,
        },
        /*{
          title: "Agregator Bill",
          key: "agregatorBill",
          url: slugs.agre_bill,
        },
       /* {
          title: "Tech Overview",
          key: "techOverview",
          url: slugs.overview_tech,
        },
       /* {
          title: "Simulated Users",
          key: "simulatedUsers",
          url: slugs.tech_users_overview,
        },
        /*{
          title: "CRM",
          key: "crm",
          url: slugs.crm,
        },
        {
          title: "Invoice / Receipt",
          key: "invoice",
          url: slugs.reciepts,
        },*/
        /*{
          title: "Third Party Acess",
          key: "thirdParty",
          url: slugs.corp,
        },
        {
          title: "Tech Key Account Prices",
          key: "techKeyAccount",
          url: slugs.api_key,
        },*/
      ],
    },
    {
      title: "Waya Official",
      key: "wayaOfficail",
      icon: <Icon component={WayaUserIcon} />,
      url: slugs.waya_official,
    },
    {
      title: "Loan",
      key: "loan",
      icon: <Icon component={TransactionsIcon} />,
      url: slugs.loan,
    },
   /* {
      title: "Users Features",
      key: "usersFeatures",
      icon: <Icon component={UserFeaturesIcon} />,
      url: slugs.users_feature,
    },*/
    {
      title: "Contents",
      key: "contents",
      icon: <Icon component={ContentIcon} />,
      children: [
        {
          title: "Terms and Conditions",
          key: "termsCondition",
          url: slugs.terms_conditions,
        },
        {
          title: "Privacy Policy",
          key: "privacy",
          url: slugs.privacy,
        }
      ]
    },
    {
      title: "FeedBack",
      key: "feedback",
      icon: <Icon component={TechIcon} />,
      url: slugs.feedback,
    }
    
  ];
}
