const slugs = {
  // private
  dashboard: "/dashboard",
  dashboardAnalysis: "/dashboard-analysis",
  users: "/users",
  manage_team: "/users/manage_team/:id",
  manage: "/users/manage",
  manage_roles: "/users/manage_roles",
  private_users: "/users/private_users",
  admin: "/users/admin",
  wallet_user: "/users/wallet_user",
  web_pos: "/users/web_pos",
  terminal_pos: "/users/terminal_pos",
  business_type: "/users/businessType",
  merchants: "/users/mechants",
  agents: "/users/agents",
  referral: "/users/referral",
  ref_user: "/users/ref_user",
  temporary_users: "/users/temporary_users",
  paystack_transactions: "/transactions/paystack_transactions",
  rubies_transactions: "/transactions/rubies_transactions",
  pos_transactions: "/transactions/pos_transactions",
  refunds: "/transactions/refunds",
  wallet: "/transactions/wallet",
  all_transactions: "/transactions/all",
  transfer_inceptor: "/transfer_inceptors",
  smu_transactions: "/transactions/smu",
  queued_transactions: "/transactions/queued",
  payment_request: "/transactions/payment_request",
  charges: "/transactions/charges",
  change_charge: "/transactions/change_charges",
  billers: "/transactions/billers",
  bills_pay: "/transactions/bills_payments",
  retrieve_payment: "/transactions/retrieve_payment",
  trans_commissions:"/transactions/commissions",
  trans_accounts:"/transaction/accounts",
  disputes: "/transactions/disputes",
  withdrawal: "/transactions/withdrawal",
  non_waya_transfer: "/transactions/non_waya_transfer",
  biller_info: "/transactions/biller_info",
  logs: "/logs",
  third_party_dispute_log: "/logs/third_party_dispute",
  requests_logs: "/logs/requests",
  admin_audit: "/logs/admin_audit",
  fraudManagement_log: "/logs/fraudManagement",
  admin_log: "/admin_log",
  third_party: "/third_party",
  act_log: "/logs/act_log",
  qr_codes: "/qr_codes",
  otp: "/otp",
  send_otp: "/otp/send_otp",
  verify_otp: "/verify_otp",
  marketing: "/marketing",
  chat: "/marketing/chat",
  call: "/marketing/call",
  wayagram_analytics: "/marketing/wayagram_analytics",
  bulk_sms: "/marketing/bulk_sms",
  sms_not: "/marketing/bulk_sms/sms_not",
  push_notifications: "/marketing/push_notifications",
  email: "/marketing/email",
  promotions: "/promotions",
  adverts: "/promotions/adverts",
  tech: "/tech",
  overview_tech: "/overview_tech",
  api_key: "/api_key",
  tech_pricing: "/tech/pricing",
  agre_bill: "/tech/aggregator_bill",
  tech_users_overview:"/tech/users_overview",
  simulated_users: "/tech/simulated_users",
  tech_admin: "/tech_admin",
  tech_waya_official: "/tech/waya_officical",
  crm: "/tech/crm",
  reciepts: "/tech/receipts",
  waya_transact: "/tech/waya_transact",
  mass_funding_waya: "/tech/mass_funding_waya",
  mass_funding_non_waya: "/tech/mass_funding_non_waya",
  mass_debit_non_waya: "/tech/mass_debit_non_waya",
  waya_official: "/waya_official",
  waya_activities: "/waya_official/activities",
  waya_followers: "/waya_official/followers",
  management: "/management",
  waya_analytics: "/management/waya_analytics",
  manage_posts: "/management/manage_posts",
  manage_group: "/management/manage_groups",
  manage_moments: "/management/manage_moments",
  manage_interests: "/management/manage_interests",
  group_accounts: "/management/group_accounts",
  manage_pages: "/management/manage_pages",
  manage_events: "/management/manage_events",
  manage_polls: "/management/manage_polls",
  waya_users: "/management/waya_users",
  waya_view_posts: "/management/waya_view_posts/:id",
  live: "/management/live",
  vote: "/management/vote",
  terms: "/settings/terms_condition",
  FAQ: "/settings/FAQ",
  privacy: "/settings/privacyPolicy",
  manage_donations: "/management/donations",
  hashtag: "/management/hashtag",
  manage_roles_perm: "/manage_roles_perm",
  corp: "/thirdparty/corp",
  corp_mem: "/thirdparty/corp_mem",
  corp_main: "/thirdparty/corp_main",
  edit_profile: "/edit_profile",
  generate_users: "/generate_users",
  waya_friends: "/waya_friends",
  report: "/report",
  dispute: "/dispute",
  feedback: "/feedback",
  waya_logs: "/waya_logs",
  hashtagpost: "/hashtagpost",
  kyc: "/manage_kyc",
  kyc_docs: "/kyc_docs",
  kyc_settings: "/kyc/kyc_settings",
  user_tier_levels: "/kyc/user_tier_levels",
  business_types: "/kyc/business_types",
  terms_conditions: "/terms_and_condition",
  privacy: "/privacy",
  export: "/export",
  waya_comm: "/waya_commission",
  waya_simu: "/waya_simulated",
  waya_post: "/waya_post",
  product: "/product_post",
  waya_like_page: "/waya_like_page",
  transaction_charge: "/transaction_charge",
  waya_simulated_users: "/waya_simulated_users",
  waya_simulated_page: "/waya_simulated_page",
  waya_simulated_group: "/waya_simulated_group",
  // auth
  login: "/login",
  forgotPassword: "/forgot_password",
  changePasswordFromEmail: "/forgot-password",
  web_pos_settings: "/webPos-settings/:userId",
  terminal_pos_settings: "/terminal-pos-settings/:userId",
  cards: "/cards",
  bulk_cards: "/cards/bulk",
  // wrong url
  page404: "/",
  //loan
  loan: "/loan",

};
export default slugs;
