import React, { useState, useEffect } from "react";
import moment from "moment";
import Layout from "../../components/Layout";
import useMatStyles from "./style";
import { Grid as MatGrid, Paper, Button as MatButton, Filt, Stack } from "@mui/material";
import { Button, Input, Space, Modal, Switch } from "antd";
import { ExitToAppOutlined, Filter, Filter1Outlined, FilterList, InputTwoTone } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridToolbar, GridToolbarColumnsButton } from "@mui/x-data-grid";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";

import { useTheme } from "styled-components";
import Swal from "sweetalert2";


let initial = {
  searchCategory: "",
  searchType: "",
  searchAccount: "",
  searchVendo: "",
  searchStatus: "",
  searchAmount: 1000,
  searchDate: [0, 0]

}
const TransferInceptor = () => {
  const [transactions, setTransactions] = useState([]);
  const theme = useTheme();

  const styles = useMatStyles();
  const matclasses = useMatStyles({ theme });

  const statistics = ["Total Accounts", "Active Accounts", "Inactive Accounts"];
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showFilter1, setShowFilter1] = useState(false);
  const [showTransactionReceiptModal, setShowTransactionReceiptModal] = useState(false);
  const [showFundingModal, setShowFundingModal] = useState(false);
  const [filtered, setFiltered] = useState(true)
  const [findText, setFindText] = useState(false)
  const [transaction, setTransaction] = useState({});
  const [totalCredit, setTotalCredit] = useState(0)
  const [totalDebit, setTotalDebit] = useState(0)
  const [totalCreditDebit, setTotalCreditDebit] = useState(0)
  const [accounts, setAccounts] = useState([])
  const [funding, setFunding] = useState({})
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showExport, setShowExport] = useState(false)
  const [activeAccounts,setActiveAccounts] = useState(0);
  const [inActiveAccounts, setInaactiveAccounts] = useState(0)
  const [showMass, setShowMass] = useState(false);
  const [filteredDate, setFilteredDate] = useState([])
  const [searchField, setSearchField] = useState(false)
  const [interceptors, setInterceptors] = useState({})
  const [showInceptor, setShowInceptor] = useState(false);
  
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [searchData, setSearchData] = useState(initial)
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
  };
  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination, current: 0, pageSize: value });
  };
 
  const handleApprove = (row) => {
    try {
        showLoader();
    const res = httpPost(`${customBaseUrl.walletUrl}/api/v1/intercepted-transfer/approve/${row.id}`,{});
    if  (res.status === true) {
        hideLoader();
        Swal.fire('Done', res.messsage, 'success')
    } else {
        hideLoader();
        Swal.fire('Oops!', res.messsage, 'error')
    }
} catch(err) {
    hideLoader()
} finally{
    hideLoader();
}
  }

  const handleReject = (row) => {
    try {
        showLoader();
    const res = httpPost(`${customBaseUrl.walletUrl}/api/v1/intercepted-transfer/disapprove/${row.id}`,{});
    if  (res.status === true) {
        hideLoader();
        Swal.fire('Done', res.messsage, 'success')
    } else {
        hideLoader();
        Swal.fire('Oops!', res.messsage, 'error')
    }
} catch(err) {
    hideLoader()
} finally{
    hideLoader();
}
  }
  const dropDownOptions = ["Activate", "Fund",  "Transfer", "Delete"];
  const handleDropdownAction = (item, row) => {
    if (item === "Approve") handleApprove(row);
    if (item === "Reject") handleReject(row);
    // if (item === "Activate") ActivateAccount(row, false);
    // if (item === "Delete") DeleteAccount(row);
    // if (item === "Block") ActivateAccount(row, true);
    // if (item === "Unblock") ActivateAccount(row, false);
    // if (item === "Deactivate") ActivateAccount(row, true);
  


  };
  const columns = React.useMemo(() => [
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "benefAccountNumber",
      headerName: "Beneficiary Account Number",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "beneficiaryName",
      headerName: "Beneficiary Name",
      width: 150,
      editable: false,
      sortable: true,
      description: "Amount Column",
      headerClassName: styles.tableHeader,
    },
    {
      field: "debitAccountNumber",
      headerName: "Debit Account Number",
      width: 100,
      editable: false,
      sortable: true,
      description: "Account Number",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "senderName",
      headerName: "Sender Name",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "timestamp",
      headerName: "Created At",
      description: "Time",
      sortable: true,
      width: 100,
      headerClassName: styles.tableHeader,
    },
    
    {
      field: "approved",
      headerName: "Status",
      width: 100,
      editable: false,
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => row?.approved ?'Approved': 'Not Approved'
    },
    {
      field: "id",
      headerName: " Action",
      width: 100,
      align: "right",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={ [ "Approve", "Reject"]}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    },
  ]);
  const getIncepted = async (e) => {
    e?.preventDefault();
    //setAccounts([]);
    showLoader();

    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);
    /*const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    */
    const url1 = `${customBaseUrl.walletUrl}/api/v1/intercepted-transfer?direction=DESC&page=${pagination.current}&pageSize=${pagination.pageSize}&sortBy=timestamp`;
    const url2 = `${customBaseUrl.walletUrl}/api/v1/wallet/all/accounts?fromdate=${moment(filteredDate?.fromdate ?? date).format('YYYY-MM-DD') }&page=${pagination?.current}&size=${pagination?.pageSize}&todate=${moment(filteredDate?.todate?? (new Date()).setMonth(month + 1)).format('YYYY-MM-DD')}`;
    const response = await httpGet(url1);
    if (response.status === true) {
      setAccounts(response?.data?.transfers);
      console.log(response?.data);
      //setFiltered(response.data.content)
      setMetaData(response?.data?.totalElements );
      hideLoader();
    } else {
      hideLoader();
    }
  };

  
  const getInceptors = async (page, size) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/transfer-interceptor`
    );
    if (res.status) {
      setInterceptors(res.data);
      //setFiltered(response.data.content)
      hideLoader();
    } else {
      hideLoader();
    }



  };

  const handleUpdateInceptor = async () => {
    showLoader();
    const res = await httpPut(
      `${customBaseUrl.walletUrl}/api/v1/transfer-interceptor`,
      interceptors
    );
    if (res.status) {
      setInterceptors(res.data);
      setShowInceptor(false);
      //setFiltered(response.data.content)
      hideLoader();
      Swal.fire("Done", res.message, "success");
    } else {
      hideLoader();
    }
  }
  const handleUpdateActiveInceptor = async (payload) => {
    showLoader();
    const res = await httpPut(
      `${customBaseUrl.walletUrl}/api/v1/transfer-interceptor`,
      payload
    );
    if (res.status) {
      setInterceptors(res.data);
      setShowInceptor(false);
      //setFiltered(response.data.content)
      hideLoader();
      Swal.fire("Done", res.message, "success");
    } else {
      hideLoader();
    }
  }

  useEffect(() => {
    getIncepted();
  }, [pagination.current, pagination.pageSize]);

  useEffect(() => {
    getInceptors();
  }, [showInceptor]);


 
  return (
    <>
         <Modal
            //   visible={showModal}
              open={showInceptor}
              closable={false}
              footer={null}
              centered
              onCancel={() => {
                setShowInceptor(false);
              }}
              bodyStyle={{
                padding: "23px 73px 44px 73px",
                borderRadius: "12px",
              }}
            >
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                Update Interceptor
            </h4>
            <label className="">Max Amount</label>
            <input
                className="form-control"
                type='number'
                value={interceptors?.maxAmount}
                onChange={(e) => setInterceptors({ ...interceptors, maxAmount: e.target.value })}
            />
            <label>Facial Interceptor Amount</label>
            <input
                className="form-control"
                type='number'
                value={interceptors?.maxAmountFacial}
                onChange={(e) => setInterceptors({ ...interceptors, maxAmountFacial: e.target.value })}
            />
            <label>Start Time</label>
            <input
                className="form-control"
                type='time'
                value={interceptors?.startTime}
                onChange={(e) => setInterceptors({ ...interceptors, startTime: e.target.value })}
            />
            <label>Stop Time</label>
            <input
                className="form-control"
                type='time'
                value={interceptors?.stopTime}
                onChange={(e) => setInterceptors({ ...interceptors, stopTime: e.target.value })}
            />
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }} >
                <button className="btn btn-primary" onClick={() => {
                    handleUpdateInceptor();
                }
                }>
                    Update
                </button>
                <button className="btn btn-danger" onClick={() => {
                    setShowInceptor(false);
                }
                }>
                    Cancel
                </button>
            </div>

        </Modal>
        <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >
        <div style={tool}>
            <div className={styles.dibot}>Transfer Interceptor</div>
            <div style={{ display: "flex", gap: "10px" }}>
        </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Max  Amount Interceptor</th>
              <th>Facial Amount Interceptor</th>
              <th>Status</th>
              <th>Facial Inceptor Status</th>
              <th>Amount Interceptor Status</th>
              <th>Time Interceptor  Status</th>
              <th>Start Time</th>
              <th>Stop Time</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{interceptors?.maxAmount ?? 'N/A'}</td>
                    <td>{interceptors?.maxAmountFacial ?? 'N/A'}</td>
                    <td><Switch 
                        checked={interceptors.active}
                        onChange={(e) => {
                            handleUpdateActiveInceptor({ ...interceptors, active: e });
                        }}

                        />
                    </td>
                    <td><Switch
                        checked={interceptors?.facialInterceptorActive}
                        onChange={(e) => {
                            handleUpdateActiveInceptor({ ...interceptors, facialInterceptorActive: e });
                        }}
                        
                    /></td>
                    <td>
                        <Switch
                            checked={interceptors?.amountInterceptorActive}
                            onChange={(e) => {
                                handleUpdateActiveInceptor({ ...interceptors, amountInterceptorActive: e });
                            }}
                        />
                     </td>
                    <td><Switch 
                        checked={interceptors?.timeInterceptorActive}
                        onChange={(e) => {
                            handleUpdateActiveInceptor({ ...interceptors, timeInterceptorActive: e });
                        }}
                        
                        />
                    </td>
                    <td>{interceptors?.startTime}</td>
                    <td>{interceptors?.stopTime}</td>
                    <td><button className="btn" style={{ background:"#ff6700"}} onClick={()=> setShowInceptor(true)}>Update</button></td>
                </tr>
            </tbody>
        </table>
      </MatGrid>
      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >

        <div style={{ height: 800, width: "100%" }}>
          <DataGrid
            className={styles.table}
            rows={search.searchText ? accounts.filter(req => req?.accountNo.toLowerCase()?.includes(search?.searchText.toLowerCase())) : accounts}
            density="comfortable"
            paginationMode="server"
            columns={columns}
            page={pagination.current}
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            components={{ Toolbar: GridToolbar }}

            pagination
            rowCount={metaData}
          />
        </div>
      </MatGrid>
    </>
  );
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default TransferInceptor;
