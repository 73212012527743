import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import ForgotPassword from './views/Auth/ForgotPassword';
import ResetPassword from './views/Auth/ResetPassword';
import ManageUser from './views/ManageUser';
import Login from './views/Auth/Login';
import Kyc from './views/Kyc';
import BusinessType from './views/Kyc/BusinessType';
import Pricings from './views/Tech/pricing/Pricings';
import WayaOfficial from './views/WayaOfficial';
import { ProfileContextProvider } from './store/context/ProfileContext';
import AdminVendors from './views/Tech/adminVendors';
import Dashboard from './views/dashboard';
import Admin from './views/admin';
import ManageAdminRoles from './views/admin/manageRoles';
import Profile from './views/ManageUser/profile';
import KycMainPage from './views/Kyc/kycUserTier/kycMain';
import KycSettings from './views/Kyc/kycSettings';
import UserLoginTracker from './views/ManageUser/user-activity/user-login-tracker';
import slugs from './resources/slugs';
import SendOtp from './views/Otp/sendOtp';
import Otp from './views/Otp';
import AdminAccountVerification from './views/account-verification';
import AllTransaction from './views/Transaction/AllTransaction';
import ManualReport from './views/Transaction/ManualReports';
import AggreegatorBill from './views/Tech/AggregatorBill';
import CommingSoon from './views/coming-soom';
import TransactionCharges from './views/Transaction/TransactionCharges';
import ChangeTransactionCharges from './views/Transaction/TransactionCharges/ChangeTransactionFees';
import UserActivityLog from './views/ManageUser/user-activity/activity-log';
import QR from './views/QR/QrCode';
import Dispute from './views/dispute'
import NonWayaTransfer from './views/Transaction/NonWayaTransfer/index';
import FeedbackComponent from './views/Feedback/Feedback';
import FeedBack from './views/Feedback';
import Accounts from './views/accounts';
import PaymentRequest from './views/Transaction/PaymentRequest';

import BillsPayment from './views/BillsPayment';
import TransactionHistory from './components/ManageWallet/Transaction History';
import TechOverview from './views/Tech/TechOverview';
import SimulatedUsersFund from './views/Tech/TechOverview/Funds';
import ManageRefferrals from './views/users/ManageRefferal';
import TransactionsDone from './views/users/ManageRefferal/transactionsDone';
import UsersOverview from './views/Tech/SimulatedUsers';
import SimulatedUsers from './views/Tech/SimulatedUsers/simulatedUsers';
import Commission from './views/Transaction/Commission';
import BonusEarned from './views/users/ManageRefferal/bonusEarned';
import TermsCondition from './views/contents/termsancCondition';
import Privacy from './views/contents/privacy';
import Disputes from './views/Transaction/Disputes';
import EditSuperProfile from './views/ManageUser/profile/editProfile';
import ManageTeam from './views/ManageUser/teamMembers';
import RequestLogs from './views/Logs/request-log';
import AdminAuditLogs from './views/Logs/admin-log';
import FraudManagement from './views/Logs/fraudManagement';
import MainPricing from './views/Tech/pricing/main';
import CardsMainPage from './views/cards';
import SMUTransactions from './views/Transaction/AllTransaction/smTransactions';
import DashboardAnalysis from './views/dashboard/dasboardAnalysis';
import Referrals from './views/users/ManageRefferal/referal';
import LoanOverview from './views/Loan/overview';
import ApproverLogin from './views/Auth/ApproverLogin';
import BulkCard from './views/bulkCard/manageBulkcard';
import BulkCardMain from './views/bulkCard/BulkCardsMain';
import ProfilePage from './views/ManageUser/profile/profilePage';
import TransferInceptor from './views/transfer-inceptor';



const App = () => {
  const isUserLoggedIn =
    localStorage.getItem("token") &&
    localStorage.getItem("token") !== "undefined";
  return (
    <>
      <NotificationContainer />
      <Router>
        {!isUserLoggedIn ? (
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/auth/loan/login" element={<ApproverLogin />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot_password" element={<ForgotPassword />} />
            <Route exact path="/reset_password" element={<ResetPassword />} />
            <Route exact path='/activate_account' element={<AdminAccountVerification />} />
            <Route exact path={slugs.loan} element={<LoanOverview />} />             
          </Routes>
        ) : (
          <ProfileContextProvider>
            <Routes>
              <Route exact path='/dashboard' element={<Dashboard/>}/>
              <Route exact path='/dashboard-analysis' element={<DashboardAnalysis/>}/>

              //user Module Routes
              <Route exact path='/users' element={<ManageUser />} />
              <Route exact path='/users/profile/:id' element={<Profile />} />
              <Route exact path='/users/profile/:id/page' element={<ProfilePage />} />
              <Route exact path='/admin/profile/:id' element={<EditSuperProfile />} />
              <Route exact path={slugs.manage_team} element={<ManageTeam />} />


              <Route exact path='/users/admin' element={<Admin />} />
              <Route exact path='/users_kyc/:id' element={<Kyc />} />
              <Route exact path='/kyc/user_tier_levels' element={<KycMainPage />} />
              <Route exact path='/user/activity_log/:id' element={<UserActivityLog />} />
              <Route exact path='/user/login_tracker/:id' element={<UserLoginTracker />} />

              <Route exact path='/waya_official' element={<WayaOfficial />} />
              <Route exact path='/admin/manage_roles' element={<ManageAdminRoles />} />
              <Route exact path='/kyc/kyc_settings' element={<KycSettings />} />
              <Route exact path={slugs.otp} element={<Otp />} />
              <Route exact path='/activate_account' element={<AdminAccountVerification />} />
              <Route exact path='/kyc/business_types' element={<BusinessType />} />

              //Transaction Module Routes
              <Route exact path={slugs.all_transactions} element={<AllTransaction />} />
              {/* <Route exact path={slugs.transfer_inceptor} element={<TransferInceptor />} /> */}
              <Route exact path={slugs.smu_transactions} element={<SMUTransactions />} />
              <Route expact path={slugs.transaction_charge} element={<ChangeTransactionCharges />} />
              <Route exact path={slugs.change_charge} element={<ChangeTransactionCharges />} />
              <Route exact path={slugs.non_waya_transfer} element={<NonWayaTransfer />} />
              <Route exact path={slugs.trans_accounts} element={<Accounts/>} />
              <Route exact path={slugs.payment_request} element={<PaymentRequest/>} />
              <Route exact path={slugs.trans_commissions} element={<Commission/>} />
              <Route exact path='/transactions_history/:id' element={<TransactionHistory />} />

              <Route exact path={slugs.referral} element={<ManageRefferrals />} />
              <Route exact path='/referral/no_of_transactions' element={<TransactionsDone />} />
              <Route exact path='/referral/bonus_earned' element={<BonusEarned />} />
              <Route exact path='/referrals/:id' element={<Referrals />} />
              <Route exact path={slugs.disputes} element={<Disputes />} />
              <Route exact path={slugs.report} element={<ManualReport />} />




              <Route exact path={slugs.cards} element={<CardsMainPage />} />
              <Route exact path={slugs.bulk_cards} element={<BulkCardMain />} />



              // Tech Module Routes
              <Route exact path={slugs.agre_bill} element={<AggreegatorBill />} />
              <Route exact path={slugs.tech_pricing} element={<MainPricing />} />
              <Route exact path='/tech_admin' element={<AdminVendors />} />
              <Route exact path={slugs.overview_tech} element={<TechOverview />} />
              <Route exact path='/simulated_users_fund' element={<SimulatedUsersFund />} />
              <Route exact path={slugs.tech_users_overview} element={<UsersOverview />} />
              <Route exact path={slugs.simulated_users} element={<SimulatedUsers />} />



              <Route exact path='/qr_codes' element={<QR />} />
              <Route exact path='/dispute' element={<Dispute />} />
              <Route exact path={slugs.requests_logs} element={<RequestLogs />} />
              <Route exact path={slugs.admin_audit} element={<AdminAuditLogs />} />
              <Route exact path={slugs.fraudManagement_log} element={<FraudManagement />} />



              
              // Feedback
              <Route exact path={slugs.feedback} element={<FeedBack />} />

              <Route exact path={slugs.bills_pay} element={<BillsPayment />} />

              <Route exact path={slugs.terms_conditions} element={<TermsCondition />} />
              <Route exact path={slugs.privacy } element={<Privacy />} />


              // Loan
              <Route exact path={slugs.loan} element={<LoanOverview />} />             

              <Route path='*' element={<CommingSoon />} />
              <Route path='' element={<Dashboard />} />
            </Routes>
          </ProfileContextProvider>
        )}
      </Router>
    </>
  );
};

export default App;
